<template>
  <div>
    <div v-if="isProtegeInSlot" style="text-align:left;">
      <v-navigation-drawer clipped app v-model="sys_menu_drawer"
        :mini-variant="sys_menu_mini"
        permanent
        :width="220"
      >
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(sys_menu_item, keyNames) in sys_menu_items"
              :key="keyNames"
              link
            >
              <v-list-item-icon v-if="!sys_menu_item.dropdown" @click="menu_selected(sys_menu_item.title)">
                <v-icon>{{ sys_menu_item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-icon v-else>
                <v-menu offset-x open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">{{ sys_menu_item.icon }}</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in sys_menu_item.itemList"
                      :key="index"
                    >
                      <v-list-item-title  @click="editorTypeSelected(item)">{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-icon>

              <v-list-item-content v-if="!sys_menu_item.dropdown" @click="menu_selected(sys_menu_item.title)">
                <v-list-item-title>{{ sys_menu_item.title }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content v-else>
                <v-menu offset-x open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">{{ sys_menu_item.title }}</v-list-item-title>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in sys_menu_item.itemList"
                      :key="index"
                    >
                      <v-list-item-title @click="editorTypeSelected(item)">{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group class="fixedBottom">
            <v-list-item @click.stop="sys_menu_mini = !sys_menu_mini">
              <v-list-item-icon>
                <v-btn
                  icon
                  class="collapse-icon"
                  :class="{ 'rotate-180': sys_menu_mini }"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>Collapse</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer clipped app v-model="context_menu_drawer"
        :mini-variant="context_menu_mini"
        permanent
        :width="180"
        right
      >
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(context_menu_item, key) in context_menu_items"
              :key="context_menu_item.title"
              link
            >
              <v-list-item-icon>
                <v-icon @click="context_menu_selected(context_menu_item.title)">{{ context_menu_item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title @click="context_menu_selected(context_menu_item.title)">{{ key }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group class="fixedBottom">
            <v-list-item @click.stop="context_menu_mini = !context_menu_mini">
              <v-list-item-icon>
                <v-btn
                  icon
                  class="collapse-icon"
                  :class="{ 'rotate-180': context_menu_mini }"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>Collapse</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-sheet>
        <v-card
          class="overflow-y-auto"
          :max-height="chalAreaMaxHeight"
        >
        <div id="meet" style="display: none;"></div>
          <v-card-text>
            <h2>{{challengeName}}</h2><hr><br>
            <div id="challenge_content">
              Challenge content
            </div>
          </v-card-text>
        </v-card>
        <br>
        <v-card
          class="overflow-y-auto"
          height="300"
        >
          <h1 style="text-align:center;">Chat Area</h1>
          <div id="chatArea" v-for="(chat, idx) in chatList" :key="idx" style="overflow: hidden;position: relative;">
            <div v-if="chat.incoming" class="incomingChatClass">
              <div v-if="chat.type==='Image'">
                <img width="400" :src="chat.src"/><br>
              </div>
              <div v-else>
                <div v-html="chat.content"></div>
              </div>
            </div>
            <div v-else class="outgoingChatClass">
              <div v-if="chat.type==='Image'">
                <img width="400" height="300" :src="chat.src"/><br>
              </div>
              <div v-else>
                <div v-html="chat.content"></div>
              </div>
            </div><br><br>
          </div>
        </v-card>
        <br>
        <v-card
          class="overflow-y-auto"
          height="300"
        >
          <imageUpload v-if="editorIS==='Images'" :slot_part_id=room_to_join @clicked="textEmitted" />
          <richText v-else-if="editorIS==='Rich Text'" @clicked="textEmitted" />
          <kaTeX v-else @clicked="textEmitted" />
        </v-card>
      </v-sheet>
      <v-snackbar
        v-model="showRespMsg"
        id="showRespMsg"
        :timeout="respMsgTimeout"
      >
        {{ respMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="showRespMsg = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-else>
      You do not have the access to join this Slot.<br>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import imageUpload from './ImageUpload.vue';
import kaTeX from './kaTexEditor.vue';
import richText from './RichTextEditor.vue';
import AppConfig from '../../config.js';
import axios from 'axios';
import katex from 'katex';
  export default {
    name: 'workbook_wid',
    components:{
      imageUpload,
      richText,
      kaTeX
    },
    data(){
        return{
            UI_Base_URL: AppConfig.UI_Base_URL,
            work_api_Base_URL: AppConfig.Work_API_Base_URL,
            challenge_api_Base_URL: AppConfig.Challenge_API_Base_URL,
            sched_api_Base_URL: AppConfig.Sched_API_Base_URL,
            route_wid: "",
            sys_menu_drawer: true,
            sys_menu_items: {
              ChalAreaInc: { title: 'Increase Chal Area', icon: 'mdi-magnify-plus', dropdown: false },
              ChalAreaDec: { title: 'Decrease Chal Area', icon: 'mdi-magnify-minus', dropdown: false },
              ChatCheck: { title: 'Chat-check', icon: 'mdi-chat-alert', dropdown: false },
              VideoToggle: { title: 'Video-toggle', icon: 'mdi-video-box', dropdown: false },
              AudioToggle: { title: 'Mic-toggle', icon: 'mdi-microphone', dropdown: false},
              Hangup: { title: 'Hangup', icon: 'mdi-phone-hangup', dropdown: false},
              Editors: { title: 'Editors', icon: 'mdi-clipboard-edit', dropdown: true, itemList: ["Images", "LaTeX", "Rich Text"] },
            },
            context_menu_items: {
              Home: { title: 'Home', icon: 'mdi-home-city' },
              Account: { title: 'My Account', icon: 'mdi-account' },
              Users: { title: 'Users', icon: 'mdi-account-group-outline' },
            },
            sys_menu_mini: true,
            context_menu_mini: true,
            context_menu_drawer: true,
            api: null,
            room_to_join: "test romm",
            renderChatStr: "",
            chalAreaMaxHeight: 250,
            user_acc_token: {},
            editorIS: "Rich Text",
            respMsg: "",
            showRespMsg: false,
            respMsgTimeout: 2000,
            slot_id: "",
            slotInfo: {},
            isProtegeInSlot: false,
            challengeName: "",
            chatList: []
        }
    },
    beforeMount(){
      this.room_to_join = this.$route.params.wid
    },
    async mounted(){
      console.log("In mount of workbook wid")
      console.log("route wid is:", this.$route.params.wid)
      this.user_acc_token = jwt_decode(this.$keycloak["token"])
      console.log("--user info--", this.user_acc_token)
      if(this.$route.params.wid!= undefined){
        this.route_wid= this.$route.params.wid
        console.log("valid workbook routeid")
        //this.room_to_join = this.$route.params.wid
        await this.getSlotIDFromSlotPartiID(this.room_to_join);
        await this.read_slotFn(this.slot_id)
        if(this.slotInfo["challenges"][0] != undefined && "title" in this.slotInfo["challenges"][0]){
          this.challengeName = this.slotInfo["challenges"][0]["title"]
        }
        //this.openRoom();
        this.checkUserProtegeInSlotFn();
        if(this.isProtegeInSlot){
          this.challengeName = this.slotInfo["challenges"][0]["title"]
          await this.renderChalFn(this.slotInfo["challenges"][0]["id"]);
          this.openRoom();
        }
      }
    },
    methods: {
      checkUserProtegeInSlotFn(){
        console.log("Check if user is protege in the given slot or not")
        if("id" in this.slotInfo["candidates"]){
          if(this.slotInfo["candidates"]["id"]===this.user_acc_token["sub"]){
            this.isProtegeInSlot = true
          }
        }
      },
      async getSlotIDFromSlotPartiID(slot_part_id){
        try{
          let getSlotIDResp = await axios.get(this.work_api_Base_URL + "/v/me/get_slot_id/" + slot_part_id)
          console.log("getSlotIDFromSlotPartiID resp", getSlotIDResp)
          this.slot_id = getSlotIDResp.data
        }catch(error){
          console.log("error in response of getSlotIDFromSlotPartiID", error.response)
          this.respMsg = error.response.headers["message"]
          this.showRespMsg = true
        }
      },
      async read_slotFn(slot_id){
        try{
          let readEventResp = await axios.get(this.sched_api_Base_URL + "/v/me/read_slot/" + slot_id)
          console.log("read event resp", readEventResp)
          this.slotInfo = readEventResp.data
        }catch(error){
          console.log("error in response of show event", error.response)
          this.respMsg = error.response.headers["message"]
          this.showRespMsg = true
        }
      },
      async renderChalFn(chalID){
        let challengeResp = await axios.get(this.challenge_api_Base_URL + "/v/me/seek_chal/" + chalID)
        console.log("challenge content resp", challengeResp)
        // render the challenge from challeneg details
        let chalParts = challengeResp.data["parts"]
        let renderChallenge=""
        for(let idx=0;idx<chalParts.length;idx++){
          //console.log("loop of preview content, render value", renderChallenge)
          if(chalParts[idx]["chal_part_class"] == 'Text'){
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + chalParts[idx]["chal_part_text"] + "</div>"
          }
          else if(chalParts[idx]["chal_part_class"] == 'LaTeX'){
            let renderFormat = (katex.renderToString(chalParts[idx]["chal_part_text"]) + "<br>")
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + renderFormat + "</div>"
          }
          else if(chalParts[idx]["chal_part_class"] == 'Image'){
            let resp = await axios.get(chalParts[idx]["url"], { responseType: 'arraybuffer' })
            .then((response) => {
            let image = btoa(
                new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            });
            //console.log("img resp", resp)
            let ret = "<img style='height:300px;width:300px;' src='" + resp +"' title='" + chalParts[idx]["chal_part_text"] + "'/><br>"
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + ret + "</div>"
          }
        }
        document.getElementById("challenge_content").innerHTML = renderChallenge
      },
      textEmitted(dataCatched){
        console.log("catched data is", dataCatched)
        // stringfify the caught object
        let msgStringify = JSON.stringify(dataCatched)
        console.log("msg type is", typeof msgStringify)
        this.sendMsgFn(msgStringify)
      },
      editorTypeSelected(editorType){
        console.log("type of editor selected is:", editorType)
        this.editorIS = editorType
      },
      context_menu_selected(selected){
        if(selected === "Home"){
          this.api.isAudioAvailable().then(available => {
              console.log("----in context---", available)
          });
        }
      },
      menu_selected(selected){
        if(selected === "Chat-check"){
          this.show_data();
        }
        else if(selected === "Video-toggle"){
          this.toggleVideo();
        }
        else if(selected === "Mic-toggle"){
          this.toggleAudio();
        }
        else if(selected === "Increase Chal Area"){
          if(this.chalAreaMaxHeight<=250){
            this.chalAreaMaxHeight = this.chalAreaMaxHeight + 100;
          }
        }
        else if(selected === "Decrease Chal Area"){
          if(this.chalAreaMaxHeight>=250){
            this.chalAreaMaxHeight = this.chalAreaMaxHeight - 100;
          }
        }
        else if(selected === "Hangup"){
          this.api.executeCommand('hangup');
          let workbookEndURL = this.UI_Base_URL + '/workbook'
          window.location.href = workbookEndURL
        }
      },
      toggleVideo(){
        this.api.executeCommand('toggleVideo');
      },
      toggleAudio(){
        this.api.executeCommand('toggleAudio');
      },
      tutorLeftSlotFn(tutorLeftSlot){
        // moderationParticipantRejected To detect if tutor is busy
        console.log("tutor left slot event resp", tutorLeftSlot)
        this.respMsg = "Tutor is busy, You are muted"
        this.showRespMsg = true
      },
      tutorInSlotFn(tutorInSlot){
        // moderationParticipantApproved will also caught the event when tutor open this slot
        console.log("tutor in slot event resp", tutorInSlot)
        this.respMsg = "Tutor in the slot, You can unmute"
        this.showRespMsg = true
      },
      async incomingChatFn(chatCatched){
        console.log("---chatCatched is---", chatCatched)
        await this.renderChatFn(chatCatched.message, true)
      },
      audioMuteStatusChangedFn(muteStatus){
        console.log("---mute status---", muteStatus)
        if(muteStatus.muted){
          this.sys_menu_items.AudioToggle["icon"] = 'mdi-microphone-off'
        }
        else{
          this.sys_menu_items.AudioToggle["icon"] = 'mdi-microphone'
        }
      },
      async renderChatFn(chatToRender, incoming){
        console.log("render the chat in chat area", chatToRender)
        let objChatToRender = JSON.parse(chatToRender)
        if(objChatToRender["type"] === "Image"){
          try{
            let imgGetResp = await axios.get(objChatToRender["file"], { responseType: 'arraybuffer' })
            .then((response) => {
              let image = btoa(
                  new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
              return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            });
            this.chatList.push({incoming: incoming, type: "Image", src: imgGetResp})
            /*if(!incoming){
              this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'><img width='400' src='" + imgGetResp + "'></div>"
            }
            else{
              this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'><img width='400' src='" + imgGetResp + "'></div>"
            }
            document.getElementById("chatArea").innerHTML = this.renderChatStr*/
          }catch(error){
            console.log("error in response of img get", error.response)
            this.respMsg = error.response.headers["message"]
            this.showRespMsg = true
          }
        }
        else if(objChatToRender["type"] === "KaTeX"){
          this.chatList.push({incoming: incoming, type: "KaTeX", content: objChatToRender["content"]})
          /*if(!incoming){
            this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          else{
            this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          document.getElementById("chatArea").innerHTML = this.renderChatStr*/
        }
        else if(objChatToRender["type"] === "Rich Text"){
          this.chatList.push({incoming: incoming, type: "Rich Text", content: objChatToRender["content"]})
          /*if(!incoming){
            this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          else{
            this.renderChatStr = this.renderChatStr +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          document.getElementById("chatArea").innerHTML = this.renderChatStr*/
        }
      },
      async sendMsgFn(msgToSend){
        let otherParticipant = ""
        for(const key in this.api._participants){
          if(key != this.api._myUserID){
            otherParticipant = key
          }
        }
        await this.renderChatFn(msgToSend, false)
        console.log("----other participant------", otherParticipant, "chat to send", msgToSend)
        this.api.executeCommand('sendChatMessage',
            msgToSend, //the text message
            otherParticipant, // the receiving participant ID or empty string/undefined for group chat.
            false // true if the privacy notification should be ignored. Defaulted to false.
        );
      },
      startConference() {
        try {
          const domain = "m.rasree.com";
          const options = {
            roomName: this.room_to_join,
            height: 500,
            parentNode: document.querySelector("#meet"),
            configOverwrite: {
              startWithVideoMuted: true,
              startWithAudioMuted: true,
            },
            userInfo: {
                email: this.user_acc_token["email"],
                displayName: this.user_acc_token["preferred_username"]
            }
          };

          this.api = new window.JitsiMeetExternalAPI(domain, options);
          this.api.addListener('incomingMessage', this.incomingChatFn);
          // moderationParticipantApproved will also caught the event when tutor open this slot
          this.api.addListener('moderationParticipantApproved', this.tutorInSlotFn);
          // moderationParticipantRejected To detect if tutor is busy
          this.api.addListener('moderationParticipantRejected', this.tutorLeftSlotFn);
          // Change audio toggle icon
          this.api.addListener('audioMuteStatusChanged', this.audioMuteStatusChangedFn);
          console.log("--------api--------", this.api)
        } catch (error) {
          console.error("Failed to load Jitsi API", error);
        }
      },
      openRoom() {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (window.JitsiMeetExternalAPI) {
          this.startConference();
        } else alert("Jitsi Meet API script not loaded");
      },
      show_data(){
        console.log("--------parti--------", this.api.getParticipantsInfo()[0])
      },
    },
  }
</script>

<style>

.collapse-icon {
  position: absolute;
  bottom:0;
  color: #0e0aec !important;
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}

.outgoingChatClass {
  background-color: #7cedd3; 
  display: table; 
  float: right; 
  padding: 2px; 
  border-radius: 0.25em;
}

.incomingChatClass {
  background-color: #a087ec; 
  display: table; 
  float: left; 
  padding: 2px; 
  border-radius: 0.25em;
}

</style>